import React from 'react';

const Flex=(props:any)=>{
  return (
    <div className="flex">
      {props.children}
    </div>
  );
};
export default Flex;
